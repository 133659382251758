export function cleanSource(source) {
  return JSON.parse(JSON.stringify(source));
}

export function googleGeoParse(data) {
  const googleData = {
    country: "",
    state: "",
    city: "",
    zip_code: "",
    address: "",
    longitude: "",
    latitude: "",
    app_address: ""
  };
  const list = data.place.address_components;
  googleData["longitude"] = data.longitude;
  googleData["latitude"] = data.latitude;
  googleData["app_address"] = data.name;

  for (const item of list) {
    if (item.types.includes("administrative_area_level_1")) {
      googleData["state"] = item.long_name;
    }
    if (item.types.includes("locality")) {
      googleData["city"] = item.long_name;
    }
    if (item.types.includes("country")) {
      googleData["country"] = item.long_name;
    }
    if (item.types.includes("postal_code")) {
      googleData["zip_code"] = item.long_name;
    }
  }

  return googleData;
}
