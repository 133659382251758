<template>
  <v-dialog v-model="isShow">
    <template v-slot:activator="{ on }">
      <v-btn :ripple="false" v-on="on" text small color="primary" class="btn-add-new-item">
        <span class="text-left"> + {{ $t(`add new item`) }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        ><h3 class="mr-2">{{ $t(`Select catalogue position`) }}:</h3>
        <v-spacer></v-spacer>
        <v-chip class="mr-1" small outlined v-if="catalog.class.selected">
          {{ catalog["class"].selected.title }}
          <v-icon right>
            mdi-chevron-right
          </v-icon></v-chip
        >
        <v-chip class="mr-1" small outlined v-if="catalog.kind.selected">
          {{ catalog["kind"].selected.title }}
          <v-icon right>
            mdi-chevron-right
          </v-icon>
        </v-chip>
        <v-chip class="mr-1" small outlined v-if="catalog.group.selected">
          {{ catalog["group"].selected.title }}
          <v-icon right>
            mdi-chevron-right
          </v-icon>
        </v-chip>
        <v-chip class="mr-1" small outlined v-if="catalog.type.selected">
          {{ catalog["type"].selected.title }}
        </v-chip>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="add-model-modal">
        <v-window v-model="step" class="pa-4">
          <v-window-item :value="1">
            <v-row>
              <!--  ------------------- CLASS  -->
              <v-col md="3" sm="6" xs="12">
                <search-items
                  search-placeholder="Class"
                  type="class"
                  :catalogue-data.sync="catalog['class']"
                  :change-item="changeClass"
                  :search="search"
                ></search-items>
              </v-col>
              <!--  ------------------- KIND  -->
              <v-col md="3" sm="6" xs="12">
                <search-items
                  search-placeholder="Kind"
                  type="kind"
                  :catalogue-data.sync="catalog['kind']"
                  :change-item="changeKind"
                  :search="search"
                  :is-disabled="
                    !catalog['class'].selected || !catalog['class'].selected.has_children
                  "
                  :parend-id.sync="catalog['class'].selected.id"
                ></search-items>
              </v-col>
              <!--  ------------------- GROUP  -->
              <v-col md="3" sm="6" xs="12">
                <search-items
                  search-placeholder="Group"
                  type="group"
                  :catalogue-data.sync="catalog['group']"
                  :is-disabled="!catalog['kind'].selected || !catalog['kind'].selected.has_children"
                  :change-item="changeGroup"
                  :search="search"
                  :parend-id.sync="catalog['kind'].selected.id"
                ></search-items>
              </v-col>
              <!--  ------------------- TYPE  -->

              <v-col md="3" sm="6" xs="12">
                <search-items
                  search-placeholder="Type"
                  type="type"
                  :catalogue-data.sync="catalog['type']"
                  :is-disabled="
                    !catalog['group'].selected || !catalog['group'].selected.has_children
                  "
                  :change-item="changeType"
                  :search="search"
                  :parend-id.sync="catalog['group'].selected.id"
                ></search-items>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="comment.comment">
                <v-alert border="top" colored-border type="info" elevation="2">
                  <strong>{{ comment.title }}:</strong> {{ comment.comment }}
                </v-alert>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="2">
            <v-row>
              <v-col>
                <label class="input-label required"> {{ $t(`Model Name`) }}: </label>
                <v-text-field
                  id="modelName"
                  :label="$t(`Model Name`)"
                  aria-autocomplete="none"
                  autocomplete="off"
                  solo
                  v-model="modelName"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!--  ------------------- MANUFACTURER  -->
              <v-col>
                <v-row>
                  <v-col md="12">
                    <search-items
                      :search-placeholder="$t('Manufacturer')"
                      type="manufacturer"
                      :catalogue-data.sync="manufacturer"
                      :change-item="changeManufacturer"
                      :search="searchManufacturer"
                    >
                    </search-items>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="10">
                    <v-text-field
                      :label="$t('Enter New Manufacturer Title')"
                      solo
                      v-model="newManufacturer"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      fab
                      class="float-right"
                      color="primary"
                      @click="addManufacturer"
                      :disabled="!newManufacturer"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <!--  ------------------- BRAND  -->
              <v-col>
                <v-row>
                  <v-col md="12">
                    <search-items
                      :search-placeholder="$t('Brand')"
                      type="brand"
                      :catalogue-data.sync="brand"
                      :change-item="changeBrand"
                      :search="searchBrand"
                      :parend-id.sync="manufacturer.selected.id"
                    >
                    </search-items>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="10">
                    <v-text-field
                      :label="$t('Enter New Brand Title')"
                      solo
                      v-model="newBrand"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      fab
                      color="primary"
                      @click="addBrand"
                      class="float-right"
                      :disabled="!newBrand"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="step === 2" color="primary" outlined @click="step = 1">
          {{ $t(`Back`) }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="step === 1" color="primary" @click="next">
          {{ $t(`Next`) }}
        </v-btn>

        <v-btn v-if="step === 2" color="primary" @click="submit">
          {{ $t(`Add model`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import SearchItems from "@/components/SearchItems.vue";
import {
  getEquipmentNodes,
  getEquipmentMachines,
  getManufacturersList,
  getManufacturersBrandsList,
  createNodeModel,
  createMachineModel,
  createManufacturerOrBrand
} from "@/api";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";

const CATALOG_DEFAULT = {
  class: {
    search: undefined,
    items: [],
    loading: false,
    selected: ""
  },
  kind: {
    search: undefined,
    items: [],
    loading: false,
    selected: ""
  },
  group: {
    search: undefined,
    items: [],
    loading: false,
    selected: ""
  },
  type: {
    search: undefined,
    items: [],
    loading: false,
    selected: ""
  }
};

const MANUFACTURER_DEFAULT = {
  search: undefined,
  items: [],
  loading: false,
  selected: ""
};
const BRAND_DEFAULT = {
  search: undefined,
  items: [],
  loading: false,
  selected: ""
};

export default {
  name: "add-model-dialog",
  components: { "search-items": SearchItems, ErrorsSnackbar, InfoSnackbar },
  props: ["type"],
  data: function() {
    return {
      comment: {
        title: "",
        comment: ""
      },
      errors: [],
      showWarnings: false,
      showInfo: false,
      infoMessage: "",
      warnings: [],
      step: 1,
      modelName: "",
      isShow: false,
      catalog: cloneDeep(CATALOG_DEFAULT),
      manufacturer: cloneDeep(MANUFACTURER_DEFAULT),
      brand: cloneDeep(BRAND_DEFAULT),
      selectedManufacturer: null,
      selectedBrand: null,
      newManufacturer: "",
      newBrand: ""
    };
  },
  methods: {
    submit() {
      const manufacturerTitle = this.manufacturer.selected.title;
      const brandTitle = this.brand.selected.title;
      let equipmentMachineId = null;

      if (this.catalog["type"].selected) {
        equipmentMachineId = this.catalog["type"].selected.id;
      } else if (this.catalog["group"].selected) {
        equipmentMachineId = this.catalog["group"].selected.id;
      } else if (this.catalog["kind"].selected) {
        equipmentMachineId = this.catalog["kind"].selected.id;
      } else if (this.catalog["class"].selected) {
        equipmentMachineId = this.catalog["class"].selected.id;
      } else {
        return;
      }

      if (this.type === "machine") {
        this.createMachineModelRequest({
          manufacturer_title: manufacturerTitle,
          brand_title: brandTitle,
          title: this.modelName,
          equipment_machine_id: equipmentMachineId
        });
      } else {
        this.createNodeModelRequest({
          manufacturer_title: manufacturerTitle,
          brand_title: brandTitle,
          title: this.modelName,
          equipment_node_id: equipmentMachineId
        });
      }
    },
    createMachineModelRequest(model) {
      createMachineModel({
        machine_model: model
      })
        .then(() => {
          this.showWarnings = false;
          this.infoMessage = this.$t("Machine model was successfully created");
          this.showInfo = true;

          this.$emit("modelWasAdded", true);
          this.isShow = false;
          this.brand.loading = false;
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
          this.showWarnings = true;
        });
    },
    createNodeModelRequest(model) {
      createNodeModel({
        node_model: model
      })
        .then(() => {
          this.showWarnings = false;
          this.infoMessage = this.$t("Node model was successfully created");
          this.showInfo = true;

          this.$emit("modelWasAdded", true);
          this.isShow = false;
          this.brand.loading = false;
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
          this.showWarnings = true;
        });
    },
    search(val, type, parentId) {
      // if (this.catalog[type].loading) return;
      this.catalog[type].loading = true;
      if (this.type === "machine") {
        getEquipmentMachines({ title: val, parent_id: parentId, type: "equipment_" + type })
          .then(r => {
            this.catalog[type].items = r.data;
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => (this.catalog[type].loading = false));
      } else if (this.type === "node") {
        getEquipmentNodes({ title: val, parent_id: parentId, type: "equipment_" + type })
          .then(r => {
            this.catalog[type].items = r.data;
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => (this.catalog[type].loading = false));
      }
    },
    clearData() {
      this.catalog["class"].selected = "";
      this.catalog["class"].search = "";
      this.catalog["class"].items = [];
      this.catalog["kind"].items = [];

      this.manufacturer.selected = "";
      this.manufacturer.search = "";
      this.manufacturer.items = [];

      this.brand.selected = "";
      this.brand.search = "";
      this.brand.items = [];
      this.step = 1;
      this.changeClass("");
      this.search("", "class");
      this.searchManufacturer("");
    },
    changeClass(item) {
      this.catalog["class"].search = item ? item.title : "";

      this.catalog["kind"].selected = "";
      this.catalog["kind"].items = [];
      this.catalog["kind"].search = "";
      this.catalog["group"].selected = "";
      this.catalog["group"].items = [];
      this.catalog["group"].search = "";
      this.catalog["type"].selected = "";
      this.catalog["type"].items = [];
      this.catalog["type"].search = "";

      if (item != null && Object.hasOwnProperty.call(item, "id")) {
        this.search("", "kind", item ? item.id : "");
        this.comment = item.comment ? item : "";
      } else {
        this.comment = "";
      }
    },
    next() {
      this.step = 2;
    },
    changeKind(item) {
      this.catalog["kind"].search = item ? item.title : "";
      this.catalog["group"].selected = "";
      this.catalog["group"].search = "";
      this.catalog["group"].items = [];
      this.catalog["type"].selected = "";
      this.catalog["type"].search = "";
      this.catalog["type"].items = [];
      if (item != null && Object.hasOwnProperty.call(item, "id")) {
        this.search("", "group", item ? item.id : "");
        this.comment = item.comment ? item : "";
      } else {
        this.comment = "";
      }
    },
    changeGroup(item) {
      this.catalog["group"].search = item ? item.title : "";
      this.catalog["type"].selected = "";
      this.catalog["type"].search = "";
      this.catalog["type"].items = [];
      if (item != null && Object.hasOwnProperty.call(item, "id")) {
        this.search("", "type", item ? item.id : "");
        this.comment = item.comment ? item : "";
      } else {
        this.comment = "";
      }
    },
    changeType(item) {
      if (item != null && Object.hasOwnProperty.call(item, "comment")) {
        this.comment = item.comment ? item : "";
      } else {
        this.comment = "";
      }
    },

    searchManufacturer(title) {
      const searchTitle = title ? title : this.manufacturer.search;
      this.manufacturer.loading = true;
      getManufacturersList({ title: searchTitle })
        .then(r => {
          this.manufacturer.items = r.data;
          this.manufacturer.loading = false;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.brand.loading = false));
    },

    searchBrand(title, manufacturerId) {
      const manufactureSelectedId =
        typeof manufacturerId === "number" && manufacturerId > 0
          ? manufacturerId
          : this.manufacturer.selected.id;

      this.brand.loading = true;
      getManufacturersBrandsList({
        title: title,
        manufacturer_id: Number(manufactureSelectedId) ?? 0
      })
        .then(r => {
          this.brand.loading = false;
          this.brand.items = r.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.brand.loading = false));
    },
    changeManufacturer(item) {
      if (!this.manufacturer) {
        this.manufacturer = MANUFACTURER_DEFAULT;
      }
      this.manufacturer.search = Object.hasOwnProperty.call(item, "title") ? item.title : item;
      this.brand.selected = "";
      this.brand.search = "";
      this.brand.items = [];
      if (item != null && Object.hasOwnProperty.call(item, "id")) {
        this.searchBrand("", item ? item.id : "");
      }
    },
    addManufacturer() {
      if (this.newManufacturer) {
        createManufacturerOrBrand({
          manufacturer_title: this.newManufacturer
        })
          .then(() => {
            this.showWarnings = false;
            this.infoMessage = this.$t("Manufacturer was successfully created!");
            this.showInfo = true;
            this.searchManufacturer("");
          })
          .catch(e => {
            this.warnings = e.data.errors;
            this.showWarnings = true;
            console.error(e);
          })
          .finally(() => {
            this.newManufacturer = "";
            this.searchManufacturer("");
          });
      }
    },
    changeBrand(item) {
      this.brand.search = item ? item.title : "";
    },
    addBrand() {
      if (this.newBrand) {
        createManufacturerOrBrand({
          manufacturer_title: this.newManufacturer || this.manufacturer.selected.title,
          manufacturer_id: this.manufacturer.selected.id,
          brand_title: this.newBrand
        })
          .then(() => {
            this.showWarnings = false;
            this.infoMessage = this.$t("Brand was successfully created!");
            this.showInfo = true;
            const id = Object.hasOwnProperty.call(this.manufacturer, "selected")
              ? this.manufacturer.selected.id
              : "";
            this.searchBrand("", id);
          })
          .catch(e => {
            this.warnings = e.data.errors;
            this.showWarnings = false;
          })
          .finally(() => {
            this.newBrand = "";
            this.isShownAddNewBrandBtn = false;
          });
      }
    }
  },

  watch: {
    searchClass(val) {
      this.search(val, "class");
    },
    searchKind(val) {
      const id = Object.hasOwnProperty.call(this.catalog["class"], "selected")
        ? this.catalog["class"].selected.id
        : "";
      this.search(val, "kind", id);
      this.comment = val.comment || "";
    },
    searchGroup(val) {
      const id = Object.hasOwnProperty.call(this.catalog["kind"], "selected")
        ? this.catalog["kind"].selected.id
        : "";
      this.search(val, "group", id);
      this.comment = val.comment || "";
    },
    searchType(val) {
      const id = Object.hasOwnProperty.call(this.catalog["group"], "selected")
        ? this.catalog["group"].selected.id
        : "";
      this.search(val, "type", id);
      this.comment = val.comment || "";
    },
    isShow: function(val) {
      if (val) {
        this.clearData();
        this.$emit("modelWasAdded", false);
      }
    }
  },
  mounted() {
    this.search("", "class");
    this.searchManufacturer("");
  }
};
</script>

<style lang="scss">
.search-model-box {
  &__list {
    max-height: 200px;
    overflow: auto;
  }
}

.add-model-modal {
  min-height: 400px;
  .v-list-item {
    min-height: 30px;
  }
}
.btn-add-new-item {
  padding: 0 !important;
  font-size: 20px;
}

.v-btn:not(.v-btn--round).v-size--small.btn-add-new-item:hover,
.v-btn:not(.v-btn--round).v-size--small.btn-add-new-item:focus,
.v-btn:not(.v-btn--round).btn-add-new-item:active {
  &:before {
    background: transparent !important;
  }
}
</style>
