<template>
  <v-card class="search-model-box">
    <v-text-field
      full-width
      solo
      clearable
      prepend-inner-icon="mdi-magnify"
      hide-details
      :placeholder="placeholder"
      v-model="model.search"
      v-on:keyup.native="search(model.search, kind, parendId)"
      :disabled="isDisabled"
      @change="changeItem(model.search, kind, parendId)"
      @focusout="search(model.search, kind, parendId)"
      @click:prepend-inner="search(model.search, kind, parendId)"
      @click:clear="
        selectedClass = '';
        model.search = '';
        search('', kind, parendId);
      "
      :loading="model.loading"
    >
    </v-text-field>
    <v-list dense min-height="250px">
      <v-list-item>
        <slot></slot>
      </v-list-item>
      <v-list-item class="primary--text" v-if="!model.items && !model.items.length">
        {{ $t("No items") }}
      </v-list-item>
      <v-list-item-group
        v-if="model.items && model.items.length"
        v-model="model.selected"
        class="search-model-box__list"
      >
        <v-list-item
          class="px-1"
          :id="item.id"
          color="primary"
          v-for="item in model.items"
          :key="item.id"
          :value="item"
          :disabled="item.title == model.selected.title"
          @click="changeItem(item)"
        >
          <v-list-item-icon class="mr-0">
            <v-icon small v-text="'mdi-information-outline'" v-if="item.comment"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.title"></v-list-item-title>

          <v-list-item-icon v-if="item.has_children">
            <v-icon v-text="'mdi-chevron-right'"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "search-items",
  props: [
    "searchPlaceholder",
    "catalogueData",
    "changeItem",
    "search",
    "type",
    "isDisabled",
    "parendId"
  ],
  data: function() {
    return {
      placeholder: this.searchPlaceholder,
      model: this.catalogueData,
      kind: this.type
    };
  }
};
</script>

<style></style>
